import styled from "styled-components"

import { Popover, Tooltip } from "@material-ui/core"

import { greyScale } from "src/ui/colors"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function MonitoringPopup(props: {
  title: string
  description: string
  buttons: {
    id: string
    icon: React.ReactNode
    onClick: () => void
    label: React.ReactNode
    disablePointerEvents?: boolean
    disableOnWarning?: boolean
    tooltip?: React.ReactNode
  }[]
  error?: boolean | null
  anchorEl: HTMLElement | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
  onClose: (props: any) => void
  open: boolean
  warning?: string
  info?: React.ReactNode
  children?: React.ReactNode
  wide?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
  violations?: any
  subtitle?: React.ReactNode
}) {
  const inViolation = Boolean(props.violations?.length)
  const warningText =
    props.warning || "Update your plan to be able to use this feature"

  function isButtonDisabled(disableOnWarning?: boolean) {
    return disableOnWarning && inViolation
  }

  return (
    <Popover
      open={props.open}
      anchorEl={props.anchorEl}
      onClose={props.onClose}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
    >
      {props.error ? (
        <Box error={props.error}>
          <MBanner type="error">{props.error}</MBanner>
        </Box>
      ) : (
        <Box $wide={props.wide}>
          <Title>
            <MText variant="heading2">{props.title}</MText>
          </Title>

          <MText variant="subtitle">
            <MText variant="bodyS" color="secondary">
              {props.subtitle}
            </MText>
          </MText>

          <Description>
            <MText variant="bodyS" color="secondary">
              {props.description}
            </MText>
          </Description>

          <Buttons>
            {props.buttons.map((button) => {
              const buttonComponent = (
                <StyledButton
                  onClick={button.onClick}
                  key={button.id}
                  $disablePointerEvents={button.disablePointerEvents}
                  disabled={isButtonDisabled(button.disableOnWarning)}
                >
                  {button.icon}
                  <Label>{button.label}</Label>
                </StyledButton>
              )

              return button.tooltip ? (
                <Tooltip
                  title={button.tooltip}
                  placement="top"
                  arrow
                  key={button.id}
                >
                  <div>{buttonComponent}</div>
                </Tooltip>
              ) : (
                buttonComponent
              )
            })}
          </Buttons>
          {props.children}
          {inViolation && (
            <MBanner type="warning" style={{ marginTop: spacing.XS }}>
              {warningText}
            </MBanner>
          )}
          {!!props.info && (
            <MText
              variant="bodyS"
              color="secondary"
              style={{ textAlign: "left" }}
            >
              {props.info}
            </MText>
          )}
        </Box>
      )}
    </Popover>
  )
}

const Box = styled.div<{
  error?: boolean
  $wide?: boolean
}>`
  max-width: ${(props) =>
    props.error ? "600px" : props.$wide ? "525px" : "400px"};
  padding: ${(props) => (props.error ? 0 : `${spacing.XL}`)};
  text-align: center;
`

const Title = styled.div`
  margin-bottom: ${spacing.XS};
`

const Subtitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${spacing.XS};
`

const Description = styled.div`
  margin: ${spacing.M} 0;
`

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`

const StyledButton = styled.button<{ $disablePointerEvents?: boolean }>`
  min-width: 80px;
  position: relative;
  padding: ${spacing.S};
  text-align: center;
  font-size: 0.875rem;
  pointer-events: ${(props) =>
    props.$disablePointerEvents ? "none" : "inherit"};
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  background: none;
  color: inherit;
  border: none;
  cursor: pointer;
  border-radius: 0.25rem;

  &:hover {
    background: ${greyScale[50]};
  }
`

const Label = styled.div`
  font-size: 0.875rem;
  margin-top: ${spacing.XS};
  font-weight: 400;
`
