import { usePostTrackEventWithParams } from "./analyticsQueries"

type TSmokingDetectionEventTypes = "Cigarette Feedback Link Clicked"

type TCigaretteDetectionFeedbackProps = {
  type: "reported_events" | "unreported_events"
  context: "feature_card"
}

function usePostSmokingDetectionAnalyticsEvent<
  TEventProperties extends TCigaretteDetectionFeedbackProps,
>(event: TSmokingDetectionEventTypes) {
  return usePostTrackEventWithParams<
    TSmokingDetectionEventTypes,
    TEventProperties
  >({
    event,
  })
}

export function usePostSmokingDetectedClicked() {
  return usePostSmokingDetectionAnalyticsEvent<{
    type: "reported_events"
    context: "feature_card"
  }>("Cigarette Feedback Link Clicked")
}
export function usePostSmokingNotDetectedClicked() {
  return usePostSmokingDetectionAnalyticsEvent<{
    type: "unreported_events"
    context: "feature_card"
  }>("Cigarette Feedback Link Clicked")
}
