import styled from "styled-components"

import { CircularProgress } from "@material-ui/core"
import { lighten } from "polished"

import { useTranslate } from "src/i18n/useTranslate"
import { greyScale } from "src/ui/colors"
import { fontWeight } from "src/ui/fontWeight"
import { MBanner, TAlert } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function MonitoringButton({
  icon,
  label,
  labelType = "default",
  updating = false,
  hideStatus,
  row = false,
  children,
  ...props
}: {
  icon: React.ReactNode
  label: React.ReactNode
  labelType: TAlert
  updating?: boolean
  row?: boolean
  hideStatus?: boolean
  children?: React.ReactNode
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (e: any) => void
  disabled?: boolean
}) {
  if (label === undefined || label === null) {
    throw Error("MonitoringButton requires 'label' to be defined")
  }

  return (
    <StyledButton {...props} $row={row} style={{ border: "none" }}>
      <Box $row={row}>
        <IconBox $row={row}>{icon}</IconBox>
        {!row && <Label>{children}</Label>}
        {!hideStatus && (
          <>
            {updating ? (
              <IndicatorLabel $compact={row}>
                <CircularProgress size={17} />
              </IndicatorLabel>
            ) : (
              <IndicatorLabel $compact={row}>
                {row ? (
                  <MText as="span" variant="bodyS" color="secondary">
                    {label}
                  </MText>
                ) : (
                  <MBanner type={labelType} size="small">
                    {label}
                  </MBanner>
                )}
              </IndicatorLabel>
            )}
          </>
        )}
      </Box>
    </StyledButton>
  )
}

export function OnOffStatusLabel({ isOn }: { isOn: boolean }) {
  const { t, langKeys } = useTranslate()

  if (isOn) {
    return (
      <MText color="primary" variant="bodyS" style={{ lineHeight: 0 }}>
        {t(langKeys.on)}
      </MText>
    )
  }

  return (
    <MText color="tertiary" variant="bodyS" style={{ lineHeight: 0 }}>
      {t(langKeys.off)}
    </MText>
  )
}

const StyledButton = styled.button<{ $row: boolean }>`
  padding: ${(props) =>
    props.$row ? `${spacing.XS} ${spacing.S}` : spacing.L};
  position: relative;
  display: block;
  font-size: 0.975rem;
  font-weight: ${fontWeight.medium};
  border-radius: 0.5rem;
  border: 1px solid ${greyScale[100]};
  background: ${(props) => `${props.$row ? "transparent" : greyScale[50]}`};
  font-family: inherit;
  cursor: pointer;
  outline: none;

  &:hover {
    background: ${lighten(0.01, greyScale[50])};
  }
`

const Box = styled.div<{ $row: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${(props) => (props.$row ? "row" : "column")};
  min-height: ${(props) => (props.$row ? "auto" : "9rem")};
`

const Label = styled.div`
  margin: ${spacing.S} 0 ${spacing.XS2};
`

const IndicatorLabel = styled.div<{ $compact: boolean }>`
  min-width: ${(props) => (props.$compact ? "2.0rem" : "3.5rem")};
`

const IconBox = styled.div<{ $row: boolean }>`
  width: ${(props) => (props.$row ? "30px" : "70px")};
  margin-right: ${(props) => (props.$row ? spacing.XS2 : 0)};
`
